<template>
  <div class="chartCard">
    <div class="chartCard-title">{{data.tagzhcn}}</div>
    <div class="chartCard-detail" v-show="showDetail" @click="$emit('detail', data.name)">{{ $t('other.views.home.detail') }}</div>
    <div class="chartCard-content">
      <div class="chartCard-content__label">{{ $t('tracked.taskCount') }}</div>
      <div class="chartCard-content__data">
        <span class="chartCard-content__data--text1">{{data.count.toLocaleString()}}</span>
        <span class="chartCard-content__data--text2" :class="{
          'chartCard-content__data--up': data.sequential_count > 0,
          'chartCard-content__data--down': data.sequential_count < 0,
        }">{{ $t('tracked.m_on_m') }} {{data.sequential_count}}</span>
      </div>
      <div class="chartCard-content__list">
        <div class="chartCard-content__list--item">
          <img class="chartCard-content__list--img" src="@/assets/img/home/chartCard-hash.svg">
          {{ $t('tracked.hashCount') }} : {{getNumber(data.hash_count)}}</div>
        <div class="chartCard-content__list--item">
          <img class="chartCard-content__list--img" src="@/assets/img/home/chartCard-ip.svg">
          {{ $t('tracked.ipCount') }} : {{getNumber(data.ip_count)}}</div>
        <div class="chartCard-content__list--item">
          <img class="chartCard-content__list--img" src="@/assets/img/home/chartCard-domain.svg">
          {{ $t('tracked.domainCount') }} : {{getNumber(data.domain_count)}}</div>
      </div>
      <div class="chartCard-content__chart" ref="chart"></div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import * as echarts from 'echarts'
import { t, isZh } from 'app/i18n'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    this.chart = null
    return {
      chartOption: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: 'rgba(1,255,172,0.2)',
              type: 'solid'
            }
          },
          backgroundColor: 'rgba(16,18,27,0.8)',
          borderRadius: 4,
          textStyle: {
            color: '#ffffff'
          },
          padding: 10,
          borderWidth: 0,
          formatter: (e) => {
            console.log(e)
            return `${t('tracked.date')}：${e[0].data.tooltipDate}<br />${t('tracked.taskCount')}：${e[0].data.value}`
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#3d4852',
              width: 1
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            margin: 16,
            textStyle: {
              color: '#8a9099',
              fontSize: 14
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          top: '3%',
          containLabel: true
        },
        series: {
          name: t('other.views.home.taskNum'),
          type: 'line',
          data: [],
          smooth: true,
          showSymbol: true,
          symbolSize: 1,
          borderWidth: 10,
          itemStyle: {
            normal: {
              lineStyle: {
                width: 2,
                color: '#00ab7a'
              },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0,171,122,0.36)'
                }, {
                  offset: 1,
                  color: 'rgba(0,171,122,0)'
                }])
              },
              // color: "rgb(1, 221, 255)"
              color: '#00ab7a'
            }
          },
          symbol: 'emptyCircle'
        }
      }
    }
  },
  watch: {
    data () {
      this.changeChartsData()
    }
  },
  mounted () {
    this.chart = echarts.init(this.$refs.chart)
    this.setChart()
    this.changeChartsData()
  },
  methods: {
    setChart () {
      this.chart.setOption(this.chartOption)
    },
    changeChartsData () {
      this.chart.setOption({
        xAxis: {
          data: this.data.histogram.map(item => dayjs(item.time_string).format('MM.DD'))
        },
        series: {
          data: this.data.histogram.map(item => {
            return {
              tooltipDate: dayjs(item.time_string).format('YYYY-MM-DD'),
              value: item.count
            }
          })
        }
      })
    },
    getNumber (num) {
      if (isZh()) {
        if (num > 100000000) {
          return (num / 100000000).toFixed(1) + '亿'
        }
        if (num > 10000) {
          return (num / 10000).toFixed(1) + '万'
        }
        return num
      } else {
        if (num > 1000000000) {
          return (num / 1000000000).toFixed(1) + 'B'
        }
        if (num > 1000000) {
          return (num / 1000000).toFixed(1) + 'M'
        }
        if (num > 1000) {
          return (num / 1000).toFixed(1) + 'K'
        }
        return num
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chartCard {
  padding-top: 34px;
  position: relative;
  width: 100%;
  transition: all .5s;
  // &:hover {
  //   filter: brightness(1.3);
  // }
  &-title {
    height: 42px;
    width: 321px;
    background-image: url('~@/assets/img/home/chartCard-title.svg');
    background-repeat: no-repeat;
    font-family: PangMenZhengDao;
    font-size: 18px;
    line-height: 42px;
    padding-left: 56px;
    position: absolute;
    top: 0;
  }
  &-detail {
    height: 28px;
    width: 107px;
    background-image: url('~@/assets/img/home/chartCard-detail.svg');
    background-repeat: no-repeat;
    color: #0091FF;
    line-height: 28px;
    position: absolute;
    right: 8px;
    text-align: center;
    cursor: pointer;
  }
  &-content {
    height: 186px;
    box-sizing: border-box;
    background-image: url('~@/assets/img/home/chartCard-bg.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    font-size: 16px;
    padding: 34px 20px 0;
    &__data {
      &--text1 {
        display: inline-block;
        // font-weight: bold;
        font-family: Bebas;
        font-size: 50px;
      }
      &--text2 {
        display: inline-block;
        margin-left: 16px;
        position: relative;
      }
      &--up {
        &::after {
          content: url('~@/assets/img/home/chartCard-up.svg');
          position: absolute;
          right: -24px;
        }
      }
      &--down {
        &::after {
          content: url('~@/assets/img/home/chartCard-down.svg');
          position: absolute;
          right: -24px;
        }
      }
    }
    &__label {
      color: #6F7B84;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    &__list {
      margin-top: 20px;
      &--item {
        display: inline-block;
        margin-left: 24px;
        margin-right: 48px;
        position: relative;
      }
      &--img {
        position: absolute;
        left: -24px;
      }
    }
    &__chart {
      width: 700px;
      height: 130px;
      position: absolute;
      right: 20px;
      top: 65px;
    }
  }
}

[data-theme='light'] {
  .chartCard {
    &-title {
      background-image: url('~@/assets/img/home/chartCard-title-light.svg');
    }
    &-content {
      background-image: url('~@/assets/img/home/chartCard-bg-light.svg');
    }
  }
}
</style>
