<template>
  <div>
    <div class="box1" v-if="showReport">
      <canvas id="canv" class="box1-bg"></canvas>
      <div class="box1-title">{{ $t('other.views.home.selectedReports') }}
        <div class="box1-title__after">Classic report</div>
      </div>
      <div class="box1-content">
        <div v-for="item in reportData" :key="item.id" class="box1-card" @click="toReport(item.id)">
          <!-- <img class="box1-card__icon" src="@/assets/img/home/eml.svg"> -->
          <img class="box1-card__icon" :src="filetypeIcon(item)">
          <div class="box1-card__number" :class="{
            'box1-card__number--high': item.score >= 7.5,
            'box1-card__number--middle':  item.score < 7.5 && item.score >= 5,
            'box1-card__number--low': item.score < 5
          }">{{item.score}}</div>
          <div class="box1-card__info">
            <div class="box1-card__title">{{item.expect_os_env[0]}} {{item.expect_os_env[1]}} {{item.expect_os_env[2]}}位</div>
            <div class="box1-card__date">{{item.added_on}}</div>
          </div>
          <div class="box1-card__tag">
            <div class="box1-card__tag--item"
              v-for="tag in item.tag_info"
              :key="tag.name"
              :class="{
                'box1-card__tag--high': tag.severity >= 3,
                'box1-card__tag--middle': tag.severity === 2,
                'box1-card__tag--low': tag.severity <= 1,
              }">{{tag.tagzhcn}}</div>
          </div>
          <div class="box1-card__bottom">
            <span>MD5</span>
            <span>{{item.md5}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <img class="box2-bg" :src="$isDark() ? require('@/assets/img/home/track-bottom-bg.png') : require('@/assets/img/home/track-bottom-bg-light.png')" alt="">
      <div class="box2-content">
        <img class="box2-title" :src="$isZh() ? ($isDark() ? require('@/assets/img/home/track-bottom-text.png') : require('@/assets/img/home/track-bottom-text-light.svg')) : ($isDark() ? require('@/assets/img/home/track-bottom-text-en.png') : require('@/assets/img/home/track-bottom-text-light-en.png'))" alt="">
        <div class="box2-btn" @click="toDashboard">{{ $t('app.intro.try') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as THREE from 'three'
import { getHomeTask } from '@/services/home'
export default {
  data () {
    return {
      reportData: []
    }
  },
  props: {
    showReport: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    getHomeTask().then(res => {
      this.reportData = res
    })
    if (this.showReport) {
      this.c = document.getElementById('canv')
      this.ctx = this.c.getContext('2d')
      this.w = this.c.width = window.innerWidth
      this.h = this.c.height = window.innerHeight
      this.t = 0
      this.run()
    }
    // this.getThree()
  },
  methods: {
    toDashboard () {
      window.open('/')
    },
    toReport (id) {
      window.open(`report/${id}`)
    },
    filetypeIcon(row) {
      if (row.os === 'android') {
        return require('@/assets/img/svg/filetype/apk.svg')
      }
      const type = row.file_type
      let file = ''
      if (row.category === 'file' || type) {
        const list = [
          'html',
          'hwp',
          'js',
          'mhtml',
          'powershell',
          'python',
          'rar',
          'rtf',
          'swf',
          'VBS-icon',
          'email'
        ]
        if (list.includes(type)) {
          file = type
        } else if (type === 'doc' || type === 'docx') {
          file = 'doc'
        } else if (type === 'xls' || type === 'xlsx') {
          file = 'excel'
        } else if (type === 'dll32' || type === 'dll64') {
          file = 'dll'
        } else if (type === 'exe32' || type === 'exe64' || type === 'nsis_exe') {
          file = 'exe'
        } else if (type === 'ppt' || type === 'pptx') {
          file = 'ppt'
        } else if (type === 'bzip2' || type === 'gzip' || type === 'zip') {
          file = 'zip'
        } else {
          file = 'unknow'
        }
      } else if (row.category === 'url') {
        file = 'IE'
      } else if (row.category === 'md5') {
        file = 'md5'
      }
      return require(`@/assets/img/svg/filetype/${file}.svg`)
    },
    run () {
      window.requestAnimationFrame(this.run)
      this.t += 1
      this.draw(33, 52 * Math.sin(this.t / 2400), this.t)
    },
    draw (a, b, t) {
      this.ctx.lineWidth = 0.1
      this.ctx.fillStyle = '#04082e'
      this.ctx.fillRect(0, 0, this.w, this.h)
      for (var i = -60; i < 60; i += 1) {
        this.ctx.strokeStyle = '#2b7ff3'
        this.ctx.beginPath()
        this.ctx.moveTo(0, this.h / 2)
        for (var j = 0; j < this.w; j += 10) {
          this.ctx.lineTo(10 * Math.sin(i / 10) +
            j + 0.008 * j * j,
          Math.floor(this.h / 2 + j / 2 *
              Math.sin(j / 50 - t / 50 - i / 118) +
              (i * 0.9) * Math.sin(j / 25 - (i + t) / 65)))
        };
        this.ctx.stroke()
      }
    }
    // getThree () {
    //   const r = 5
    //   // 创建场景
    //   const scene = new THREE.Scene()
    //   // 创建透视投影相机，视角45度，画幅比例 宽比高，近平面距离0.1，远平面1000
    //   const camera = new THREE.PerspectiveCamera(
    //     60,
    //     1,
    //     0.1,
    //     1000
    //   )
    //   // 创建渲染器
    //   const renderer = new THREE.WebGLRenderer({ alpha: true })
    //   renderer.setClearAlpha(0)
    //   // 渲染器canvas宽高设为与窗口一致
    //   renderer.setSize(370, 370)
    //   // 将渲染器对应的dom元素添加到body中
    //   this.$refs.box2.appendChild(renderer.domElement)
    //   renderer.domElement.style.position = 'absolute'
    //   renderer.domElement.style.left = 'calc(50% - 185px)'
    //   renderer.domElement.style.top = 'calc(50% - 185px)'
    //   // 定义一个几何体
    //   const geometry = new THREE.SphereGeometry(r, 10, 10)

    //   const wireframe = new THREE.WireframeGeometry(geometry)

    //   // 定义一种材质，显示为线框
    //   const material = new THREE.MeshBasicMaterial({
    //     color: 0x2f5582,
    //     wireframe: true
    //   })
    //   // 网孔(Mesh)是用来承载几何模型的一个对象，可以把材料应用到它上面
    //   // const ball = new THREE.Mesh(wireframe, material);
    //   const ball = new THREE.LineSegments(wireframe, material)

    //   // 把几何模型添加到场景中，对象被添加到原点(0,0,0)坐标。
    //   scene.add(ball)
    //   // 移动相机位置
    //   camera.position.z = 10

    //   function render () {
    //     // 渲染循环，以每秒60次的频率来绘制场景
    //     requestAnimationFrame(render)
    //     // 设置球体绕y轴旋转
    //     ball.rotation.y -= 0.004
    //     renderer.render(scene, camera)
    //   }
    //   render()
    // }
  }
}
</script>

<style lang="scss" scoped>
  .box1 {
    width: 100%;
    position: relative;
    &-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0px;
      top: 100px;
    }
    &-title {
      position: relative;
      font-size: 34px;
      text-shadow: 0 2px 4px rgba(0,10,77,0.50);
      text-align: center;
      background-image: url('~@/assets/img/home/titleBg.png');
      background-repeat :no-repeat;
      background-position: 50% 0;
      background-size: 680px 250px;
      height: 250px;
      line-height: 230px;
      &__after {
        font-family: PingFangSC;
        font-size: 14px;
        color: #A5ACCC;
        margin-top: -195px;
      }
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &-card {
      height: 204px;
      width: 378px;
      box-sizing: border-box;
      padding: 27px 22px;
      margin-bottom: 24px;
      background: rgba(23,32,87,.8);
      box-shadow: 0 2px 4px 0 #020737;
      border-radius: 4px;
      position: relative;
      cursor: pointer
      transition all .5s;
      &:hover {
        background-image: linear-gradient(180deg, RGBA(19, 37, 122, .7) 0%, RGBA(21, 41, 108, .7) 100%);
        box-shadow: 0 7px 14px 0 rgba(0,0,0,0.035), inset 0 0 10px 0 RGBA(94, 125, 247, .7);
        border-radius: 4px;
      }
      &__icon {
        width: 29px;
        height: 38px;
        display: inline-block;
        vertical-align :middle;
      }
      &__number {
        height: 28px;
        width: 66px;
        line-height: 28px;
        text-align: center;
        background: #CF605B;
        border-radius: 4px;
        font-family: Verdana-Bold;
        position: absolute;
        right: 22px;
        top: 30px;
        &--low {
          background: #3eae89;
        }
        &--middle {
          background: #e69f34;
        }
        &--high {
          background: #cf605b;
        }
      }
      &__info {
        margin-left: 13px;
        display: inline-block;
        vertical-align: middle;
      }
      &__title {
        line-height: 20px;
        margin-bottom: 6px;
      }
      &__date {
        line-height: 20px;
      }
      &__tag {
        margin-top: 18px;
        height: 60px;
        overflow: hidden;
        &--item {
          line-height :22px;
          padding: 0 9px;
          border: 1px solid #FF8D87;
          color: #FF8D87;
          border-radius: 4px;
          display: inline-block;
          margin-right: 4px;
          margin-bottom: 8px;
        }
        &--low {
          border-color: #8EA4FF;
          color: #8EA4FF;
        }
        &--middle {
          border-color: #C2AA6F;
          color: #C2AA6F;
        }
        &--high {
          border-color: #FF8D87;
          color: #FF8D87;
        }
      }
      &__bottom {
        width: 100%;
        height: 35px;
        line-height: 35px;
        background: rgba(98,97,252,.1);
        border-radius: 0 0 4px 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        & span {
          margin-left: 22px;
        }
      }
    }
  }
  .box2 {
    text-align: center;
    // height: 100vh;
    // background-image: url('~@/assets/img/home/track-bottom-bg.png');
    // background-repeat: no-repeat;
    // background-position: 50% 50%;
    // background-size: 100%;
    position: relative;
    &-bg {
      width: 100%;
      position: static;
    }
    &-content {
      width: 581px;
      transform: translateY(-50%);
      position: absolute;
      left: calc(50% - 300px);
      top: 50%;
      z-index: 1;
    }
    &-title {
      margin-bottom: 44px;
      width: 100%;
    }
    &-btn {
      width: 178px;
      height: 48px;
      line-height: 48px;
      background: #00ab7a;
      box-shadow: 0 4px 44px 0 #083f2f;
      border-radius: 4px;
      font-size: 20px;
      color: var(--color-text-1);
      margin: 0 auto;
      cursor: pointer;
      transition: all .5s;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }

[data-theme='light'] {
  .box2-btn {
    box-shadow: none;
    color: #FFFFFF;
  }
}
</style>
