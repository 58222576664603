<template>
  <div class="home">
    <div class="container">
      <div class="box1">
        <div class="box1-text1">
          <!-- <img :src="require('@/assets/img/home/track-top-text.svg')" alt=""> -->
          {{ $t('tracked.title') }}
        </div>
        <div class="box1-text2">{{ $t('tracked.titleDesc') }}</div>
        <div class="box1-content">
          <div class="box1-content__item" v-for="(item, index) in box1Data" :key="index">
            <div class="box1-content__item--label">
              <img :src="getImg(item.icon)">
              {{item.label}}</div>
            <div class="box1-content__item--value">
              <span>{{getNumber(item.value)}}</span>
              <span class="box1-content__item--after">{{item.after}}</span>
            </div>
            <img class="box1-content__item--gap" v-if="index !== 0" :src="$isDark() ? require('@/assets/img/home/tracked-box1-gap.png') : require('@/assets/img/home/tracked-box1-gap-light.png')">
          </div>
        </div>
        <img class="box1-next" src="@/assets/img/home/tracked-box1-next.svg">
      </div>
      <div class="box2">
        <div class="box2-tab">
          <div
            class="box2-tab__item"
            :class="{'box2-tab__item--active': index === tabIndex}"
            v-for="(tab, index) in tabData"
            :key="index"
            @click="tabClick(index)">
            <span style="padding-left: 20px;"><img :src="getImg(tab.icon)"></span>
            <div>{{tab.text}}</div>
          </div>
          <div class="box2-line" ref="tabLine"></div>
        </div>
        <div class="box2-radio">
          <div class="box2-radio__item" :class="{'box2-radio__item--active': radioActive === 0}" @click="radioActive = 0">{{ $t('tracked.timeScope.year') }}</div>
          <div class="box2-radio__item" :class="{'box2-radio__item--active': radioActive === 1}" @click="radioActive = 1">{{ $t('tracked.timeScope.sixMonth') }}</div>
          <div class="box2-radio__item" :class="{'box2-radio__item--active': radioActive === 2}" @click="radioActive = 2">{{ $t('tracked.timeScope.month') }}</div>
          <div class="box2-radio__item" :class="{'box2-radio__item--active': radioActive === 3}" @click="radioActive = 3">{{ $t('tracked.timeScope.week') }}</div>
        </div>
        <div
          class="box2-content"
          v-loading="isLoading"
          :element-loading-text="$t('loading')"
          element-loading-background="transparent"
        >
          <chart-card
            @detail="handleToPublic"
            v-for="(item, index) in cardListData"
            :key="index"
            :data="item"
            :showDetail="false"
            class="box2-content__item"></chart-card>
        </div>
        <div class="box2-more" v-if="listData.length > 20" @click="openMore = !openMore">
          {{openMore ? $t('action.fold') : $t('tracked.showMore')}}
          <img :class="{ isOpen: openMore }" src="@/assets/img/home/tracked-more.svg">
        </div>
        <sand-no-data v-if="listData.length === 0 && !isLoading"></sand-no-data>
      </div>
    </div>
    <index-footer :showReport="false"></index-footer>
    <backTo-top></backTo-top>
  </div>
</template>
<script setup>
import indexFooter from '../component/footer'
import chartCard from '../component/chartCard'
import sandNoData from '@/components/report/sandNoData'
import { getTrackedList, getTrackedOperating } from '@/services/home.js'
import { ref, watch, computed, onMounted } from 'vue'
import { t } from 'app/i18n'
// import { useRouter } from 'vue-router'

// const router = useRouter
const box1Data = ref([
  {
    label: t('tracked.opsCount'),
    value: '0',
    icon: 'tracked-box1-icon1.svg'
  },
  {
    label: t('tracked.blackCount'),
    value: '0',
    icon: 'tracked-box1-icon2.svg'
  },
  {
    label: t('tracked.networkCount'),
    value: '0',
    icon: 'tracked-box1-icon3.svg'
  },
  {
    label: t('tracked.behaviorCount'),
    value: '0',
    icon: 'tracked-box1-icon4.svg',
    after: t('tracked.kind')
  }
])

const tabData = [
  {
    text: t('tracked.maliciousSoftware'),
    icon: 'tracked-tab1.png',
    showDetail: true
  },
  {
    text: t('tracked.aptOrg'),
    icon: 'tracked-tab2.png',
    showDetail: true
  }
  // {
  //   text: '已知漏洞',
  //   icon: 'tracked-tab3.svg',
  //   showDetail: true
  // },
  // {
  //   text: '漏洞利用行为',
  //   icon: 'tracked-tab4.svg',
  //   showDetail: true
  // }
]
const type = ['malware', 'apt', 'cve', 'exploit']
const query = [360, 180, 30, 7]

const tabIndex = ref(0)
const radioActive = ref(0)
const openMore = ref(false)
const listData = ref([])
const isLoading = ref(false)
const tabLine = ref()
// const box1CountAfterX = ref([0])

onMounted(() => {
  getData()
  getTrackedOperating().then(res => {
    box1Data.value[0].value = res.total_count
    box1Data.value[1].value = res.black_count
    box1Data.value[2].value = res.host_count
    box1Data.value[3].value = 60
    // box1Data[3].value = res.signature_num
  })
})
watch([radioActive, tabIndex], () => {
  listData.value = []
  getData()
})

const cardListData = computed(() => {
  if (openMore.value || listData.value.length < 20) {
    return listData.value
  }
  return listData.value.slice(0, 20)
})

function getData () {
  isLoading.value = true
  getTrackedList({
    type: type[tabIndex.value],
    query: query[radioActive.value]
  }).then(res => {
    console.log(res)
    setTimeout(() => {
      listData.value = res
      isLoading.value = false
    }, 800)
  })
}
function getImg (name) {
  return require(`@/assets/img/home/${name}`)
}
function getNumber (data) {
  data = String(data).split('')
  let count = 0
  for (let i = data.length - 1; i >= 0; i--, count++) {
    if (count % 3 === 0 && count !== 0) {
      data.splice(i + 1, 0, ',')
    }
  }
  return data.join('')
}

function handleToPublic (tag) {
  // const { href } = router.resolve({ name: 'public', query: { tag } })
  window.open(`/taskhub/public`, '_blank')
}
function tabClick (index) {
  if (tabIndex.value === index) return
  tabLine.value.style.left = `${337 / 2 + index * 337 * 2}px`
  openMore.value = false
  tabIndex.value = index
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[lang="en"] {
  .box2-more {
    width: 90px;
  }
}
.home{
  height: 100%;
  width: 100%;
  background-color: #000f0b;
  background: url('~@/assets/img/home/track-top-bg.png') center top/auto 930px no-repeat;
  min-width: 1344px;
  overflow-x: auto;
}
.container {
  font-size: 14px;
  font-family: PingFangSC;
  color: var(--color-text-1);
  width: 1344px;
  // border 1px solid red
  margin: 0 auto;
}
.box1 {
  padding: 115px 0 40px 0;
  &-text1 {
    font-size: 50px;
    font-weight: bold;
    font-family: 微软雅黑;
    text-align: center;
    img {
      width: 200px;
      height: 51px;
    }
  }
  &-text2 {
    font-size: 16px;
    line-height: 24px;
    width: 900px;
    display: flex;
    margin: 40px auto;
    text-align: justify;
    text-align-last: center;
  }
  &-content {
    height: 142px;
    margin-top: 80px;
    background: rgba(26,32,36,0.8);
    display: flex;
    &__item {
      flex: 1;
      text-align: center;
      position: relative;
      &--label {
        font-weight: bold;
        font-size: 16px;
        margin-top: 30px;
        & img {
          vertical-align: middle;
          transform: translateY(-2px);
        }
      }
      &--value {
        font-size: 28px;
        color: transparent;
        background: linear-gradient(180deg, #48ffb4 0%, #00ab7a 100%);
        -webkit-background-clip: text;
        margin-top: 15px;
        font-family: Bebas;
        // font-weight: bold;
      }
      &--after {
        font-size: 16px;
        display: inline-block;
        background: linear-gradient(180deg, #48ffb4 0%, #00ab7a 100%);
        -webkit-background-clip: text;
        transform: translateX(3px);
        font-weight: normal;
      }
      &--gap {
        position: absolute;
        left: -21px;
        top: 34px;
      }
    }
  }
  &-next {
    display: inline-block;
    margin-top: 40px;
    margin-left: 50%;
    transform: translateX(-50%);
    animation: nextMove 3s infinite;
  }
}
.box2 {
  &-tab {
    display: flex;
    width: 100%;
    position: relative;
    &__item {
      height: 110px;
      border-bottom: 3px solid rgba(0,171,122,0.1);
      flex: 1;
      text-align: center;
      cursor: pointer;
      & img {
        margin-bottom: 10px;
      }
      &--active {
        font-weight: bolder;
        color: #00ab7a;
        // border-bottom-color #00ab7a
      }
    }
  }
  &-line {
    width: 337px;
    height: 3px;
    border-radius: 1px;
    background: #00ab7a;
    position: absolute;
    left: 167.5px;
    bottom: 0px;
    transition: all .5s;
  }
  &-radio {
    float: right;
    margin-top: 40px;
    &__item {
      display: inline-block;
      margin-left: 36px;
      color: #828283;
      cursor: pointer;
      &--active {
        font-weight: bolder;
        color: #00ab7a;
        position: relative;
        &::before {
          content: '';
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-image: linear-gradient(201deg, #04dd9f 0%, #00ab7a 100%);
          position: absolute;
          left: -16px;
          top: 2px;
        }
      }
    }
  }
  &-content {
    // width: 1180px;
    width: 100%;
    min-height: 200px;
    padding-top: 56px;
    padding-bottom: 10px;
    &__item {
      margin-bottom: 20px;
    }
  }
  &-more {
    width: 74px;
    margin: 0 auto;
    color: #00ab7a;
    cursor: pointer;
    & img {
      transition: all .5s;
      vertical-align: middle;
      &.isOpen {
        transform: rotate(180deg);
      }
    }
    &--open {
      opacity: 0;
      pointer-events: none;
      & img {
        transform: rotate(180deg);
      }
    }
  }
}

@keyframes nextMove {
  0% {
    transform: translateX(-50%) translateY(-5px);
  }
  30% {
    transform: translateX(-50%) translateY(5px);
  }
  100% {
    transform: translateX(-50%) translateY(-5px);
  }
}

[data-theme='light'] {
  .home {
    width: 100%;
    background: url('~@/assets/img/home/track-top-bg-light.png') center top/auto 930px no-repeat;
    overflow-x: auto;
    min-width: 1344px;
    background-color: #F8F9FA;
  }
  .box1 {
    &-content {
      background: #ffffff;
      box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.4);
      &__item--value {
        color: #242933;
      }
      &__item--after {
        color: #242933;
        background: none;
      }
    }
  }
  .box2 {
    &-tab {
      &__item {
        border-bottom: 3px solid rgba(0,171,122,0.1);
        &--active {
          color: #00ab7a;
          // border-bottom-color #00ab7a
        }
      }
    }
    &-line {
      background: #00ab7a;
    }
    &-radio {
      &__item {
        color: #828283;
        &--active {
          color: #00ab7a;
          &::before {
            background-image: linear-gradient(201deg, #04dd9f 0%, #00ab7a 100%);
          }
        }
      }
    }
    &-more {
      color: #00ab7a;
    }
  }
}
</style>
